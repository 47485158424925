import React from 'react';

function OtraPagina() {
  return (
      <div>
        <p>Estamos en el inicio</p>
      </div>
  );
}

export default OtraPagina;
