import React from 'react';


function Header() {
  return (
    <header>
      <div>Negocio Megaproyecto</div>
    </header>
  );
}

export default Header;
