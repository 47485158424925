// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import Header from './Componentes/Header';
import Main from './Componentes/Main';
import Footer from './Componentes/Footer';

import RegistroUsuario from './Pagina/RegistroUsuario';
import Comercio from './Pagina/Comercio';
import Productos from './Pagina/Productos';
import Ventas from './Pagina/Ventas';
import NotFound from './Pagina/NotFound';
import OtraPagina from './Pagina/OtraPagina';

function App() {
  const location = useLocation();
  const isHomePage = location.pathname === '/'; // Detecta si es la ruta de inicio

  return (
    <div className={`App ${isHomePage ? 'hide-header-footer' : ''}`}>
      <Header />
      <Main>
        <Routes>
          <Route path="/" element={<RegistroUsuario />} />
          <Route path="/comercio" element={<Comercio />} />
          <Route path="/productos" element={<Productos />} />
          <Route path="/ventas" element={<Ventas />} />
          <Route path="/otrapagina" element={<OtraPagina />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Main>
      <Footer />
    </div>
  );
}

// Wrapper para proporcionar el contexto del Router
function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
