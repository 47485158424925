import React from 'react';
import { createRoot } from 'react-dom/client'; // Importa createRoot desde react-dom/client
/*aplicando css a HEADER*/
import './Header.css';

/*aplicando css a FOOTER*/
import './Footer.css';

/*aplicando css a Main*/
import './Main.css';

import './RegistroUsuario.css';


import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App />);
