import React from 'react';

function Ventas() {
  return (
      <div>
        <p>Estamos en las ventas</p>
      </div>
  );
}

export default Ventas;
