import React, { useState } from 'react';

function RegistroUsuario() {
  const [formData, setFormData] = useState({
    documento: '',
    nombres: '',
    apellidos: '',
    fecha_nacimiento: '',
    correo: '',
    telefono: '',
  });

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    if (!formData.documento || !formData.nombres || !formData.apellidos || !formData.fecha_nacimiento || !formData.correo || !formData.telefono) {
      setErrorMessage('Por favor, complete todos los campos.');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch('https://house.aprendiendoingles.link/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (response.ok) {
        console.log('Datos enviados correctamente:', result);
        setSuccessMessage(result.message || 'Registro exitoso');
        setFormData({
          documento: '',
          nombres: '',
          apellidos: '',
          fecha_nacimiento: '',
          correo: '',
          telefono: '',
        });
      } else {
        console.error('Error al enviar los datos:', result.message);
        setErrorMessage(result.message || 'Error al enviar los datos.');
      }
    } catch (error) {
      console.error('Error al enviar los datos:', error);
      setErrorMessage('Error al enviar los datos.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="text" name="documento" value={formData.documento} onChange={handleChange} placeholder="Documento" />
      <input type="text" name="nombres" value={formData.nombres} onChange={handleChange} placeholder="Nombres" />
      <input type="text" name="apellidos" value={formData.apellidos} onChange={handleChange} placeholder="Apellidos" />
      <input type="date" name="fecha_nacimiento" value={formData.fecha_nacimiento} onChange={handleChange} placeholder="Fecha de Nacimiento" />
      <input type="email" name="correo" value={formData.correo} onChange={handleChange} placeholder="Correo" />
      <input type="tel" name="telefono" value={formData.telefono} onChange={handleChange} placeholder="Teléfono" />
      <button type="submit" disabled={loading}>Registrar</button>
      {loading && <p>Cargando...</p>}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
    </form>
  );
}

export default RegistroUsuario;
