import React from "react";

const NotFound = () => {
  return (
    <div className="logo-container">
      <img src="/404logo.png" alt="404 Logo" className="logo-404" />
      <h1>404 - Page Not Found</h1>
      <p>Oops! The page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFound;
