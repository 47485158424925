import React from 'react';

function Comercio() {
  return (
      <div>
        <p>Estamos en el Comercio</p>
      </div>
  );
}

export default Comercio;
