import React from 'react';

function Productos() {
  return (
      <div>
        <p>Estamos en los Productos</p>
      </div>
  );
}

export default Productos;
